import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import dateFormat from 'dateformat'
import './History.css'
import welcomeDark from './welcome_dark.png'
import welcomeLight from './welcome_light.png'
import welcome from './add_new.png'
import welcome2 from './welcomStart2.png'
import { BsFillFuelPumpFill, BsCalendarDate } from "react-icons/bs"
import { ImLocation } from "react-icons/im"
import { TfiDashboard } from "react-icons/tfi"
import { MdOutlinePayments } from "react-icons/md"
import { GiAutoRepair } from "react-icons/gi"
import { FaUserEdit } from "react-icons/fa"
import { IconContext } from "react-icons"
import { Testing } from '../Testings/Testing'
import { SideStatistics } from './SideStatistics'
import { useTranslation } from 'react-i18next'
import { updateVehicule } from '../../../actions/rappelActions'
import { NativeAdHistory } from '../../ads/NativeAdHistory'

export const getLastTwoWords = (sentence) => {

  const wordsArray = sentence.split('_');
  const filteredWords = wordsArray.filter((word) => word.trim() !== '');
  const lastTwoWords = filteredWords.slice(-2);

  // Join the last two words back into a string
  // const result = lastTwoWords.join(' ');

  return lastTwoWords;
};



export const History = ({ ip_address, currentLanguage, digit_format }) => {
  const {t}=useTranslation()
  const dispatch=useDispatch()
  const mode = useSelector(state => state.auth.mode)
  const vehicule_id = useSelector((state) => state.vehicules.item.id)
  const time_format = useSelector(state => state.auth.profile.time_format)
  const selected_vehicule_id= useSelector(state => state.vehicules.item.id)

  const [sorted_items, setSorted_items] = useState([])




  useEffect(() => {
    const fetchMyObjects = async () => {
      if (vehicule_id !== 'undefined') {
        const myItems = await getMyObjects(vehicule_id)
        console.log(vehicule_id)
        setSorted_items(myItems)
      }
      // console.log(Object.values(sorted_items[2])[0].url)
    }

    fetchMyObjects()

  }, [vehicule_id])

  const getMyObjects = async (vehicule_id) => {
    const raw_data = await fetch(`${ip_address}/app/api/ownobjects/${vehicule_id}/`, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      }
    })
    const data = await raw_data.json()
    const liste = data.data
    liste.sort(function (a, b) {
      return Number(Object.keys(b)[0]) - Number(Object.keys(a)[0]);
    });
    console.log(liste)
    return liste
  }


  const deleteService = async (url,item) => {



    const action = await fetch(url, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
      },
      method: 'DELETE',
    })
    const status = action.status

    switch (status) {
      case 204:
        if(item==0 && sorted_items.length >1){
          console.log('deleting last item of a list containing more than 1 item',Object.values(sorted_items[1])[0])
          const new_odometre=new FormData()
          new_odometre.append('odometre',Object.values(sorted_items[1])[0].odometre)
          dispatch(updateVehicule(selected_vehicule_id,new_odometre))
        }
        if(sorted_items.length ===1){
          console.log('deleting last item of a list containing 1 item')
          const new_odometre=new FormData()
          new_odometre.append('odometre',0)
          dispatch(updateVehicule(selected_vehicule_id,new_odometre))
        }
        const new_items = sorted_items.filter(item => Object.values(item)[0].url !== url)
        setSorted_items(new_items)
        break
      case 403:
        alert('this is not your item')
        break

    }
  }

  const adPlace=(array,i)=>{
    if(array.length >2 && i===1){
  return <NativeAdHistory />
    }else if(array.length===2 && i===0){
      return <NativeAdHistory />
    }else if(array.length===1){
      return <NativeAdHistory />
    }
  }


  return (
    <section className={`all_sections ${currentLanguage.dir || 'ltr'}`}>
      <div className='row history-row'>
        <div className={`col-md-7 bg-${mode} m-2`} style={{ "border-radius": "10px", "padding": "10px" }}>
          {sorted_items.length > 0 ?
            <>
              {sorted_items.map((index, item) =>
                <><Testing index={index} item={item} owner={Object.values(sorted_items[item])[0].done_by} icon={Object.values(sorted_items[item])[0].icon}
                  kind={Object.values(sorted_items[item])[0].kind} payment_method={Object.values(sorted_items[item])[0].payment_method}
                  type={Object.values(sorted_items[item])[0].type}
                  reason={Object.values(sorted_items[item])[0].raison}
                  writter={Object.values(sorted_items[item])[0].writter}
                  real_time={Object.values(sorted_items[item])[0].kind==='reminder'?(dateFormat(Object.values(sorted_items[item])[0].rappel_date, `${time_format}  HH:MM`)):(dateFormat(Object.values(sorted_items[item])[0].creation_date, `${time_format}  HH:MM`))}
                  odometre={Object.values(sorted_items[item])[0].kind==='reminder'?Object.values(sorted_items[item])[0].odometre_rappel:(Object.values(sorted_items[item])[0].kind==='route'?Object.values(sorted_items[item])[0].end_odometre:Object.values(sorted_items[item])[0].odometre)}
                  place={Object.values(sorted_items[item])[0].kind==='route'?`${Object.values(sorted_items[item])[0].origin} => ${Object.values(sorted_items[item])[0].destination}`:Object.values(sorted_items[item])[0].place} station={Object.values(sorted_items[item])[0].station} mode={mode} ip_address={ip_address}
                  deleteService={deleteService} url={Object.values(sorted_items[item])[0].url} currentLanguage={currentLanguage}
                  origin={Object.values(sorted_items[item])[0].kind==='route'?Object.values(sorted_items[item])[0].origin:"none"}
                  start_date={Object.values(sorted_items[item])[0].kind==='route'?Object.values(sorted_items[item])[0].start_date:"none"}
                  start_odometre={Object.values(sorted_items[item])[0].kind==='route'?Object.values(sorted_items[item])[0].start_odometre:"none"}
                  prixAuto_route={Object.values(sorted_items[item])[0].kind==='route'?Object.values(sorted_items[item])[0].prixAuto_route:"none"}
                  destination={Object.values(sorted_items[item])[0].kind==='route'?Object.values(sorted_items[item])[0].destination:"none"} 
                  Last_updated={dateFormat(Object.values(sorted_items[item])[0].modification_date, `${time_format}  HH:MM`)} note={Object.values(sorted_items[item])[0].note}
                  attached_file={Object.values(sorted_items[item])[0].attached_file} value={Object.keys(Object.values(sorted_items[item])[0]).includes('value')?Object.values(sorted_items[item])[0].value:(Object.values(sorted_items[item])[0].kind==='route'?Object.values(sorted_items[item])[0].prixAuto_route:Object.values(sorted_items[item])[0].price)}/>
                  {/* {adPlace(sorted_items,item)} */}
                  </>

              )}
              <img className='rounded-circle' src={mode==='light'?welcomeLight:welcomeDark} style={{ "width": "120px", "marginTop": "-20px", marginLeft :mode==='light'?-10:0 ,marginTop:-50}} /></>

            : <div style={{height:600, display:"flex",flexDirection:"column",justifyContent:'center',alignItems:"center"}}>
              <div style={{display:"flex",justifyContent:'center',alignItems:"center",marginBottom:10}}>
              {/* <img src={mode==='light'?welcomeLight:welcomeDark} style={{ width: "80%"}} /> */}
              <img src={welcome} style={{ width: "40%"}} />
              </div>
             
              <div style={{display:"felx",flexDirection:"column",justifyContent:'center',alignItems:"center"}}>
                <h3 className={`text-center text-${mode}`}>{t("Ready to start?")}</h3>
                <p className={`text-center text-${mode}`}>{t("Click ")} <i class='bx bxs-plus-circle'></i> {t(" and add your first refueling, expense, service or income.")}</p>
              </div></div>}
        </div>
        <div className={`col-md-4 bg-${mode} ${mode} m-2`} style={{ "border-radius": "10px",height:'100%' }}>
          <SideStatistics ip_address={ip_address} digit_format={digit_format} />


        </div>
      </div>
      <div className='row' style={{ "width": "80%" }}>
        {/* <div className={`col ${mode} m-2`} style={{ "border-radius": "10px", "padding": "10px" }}>
          here goes the adds
        </div> */}
      </div>


    </section>

  )
}





